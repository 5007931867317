import { apiFactory } from '../../api/apiFactory'

const rolesApi = apiFactory.get('roles')

const state = {
	roles: [],
}

const getters = {
	roles: state => state.roles,
}

const mutations = {
	SET_ROLES(state, payload) {
		state.roles = payload
	},
}

const actions = {
	async loadRoles({ commit }) {
		try {
			const { data } = await rolesApi.getAll()
			commit('SET_ROLES', data)
		} catch (error) {
			// eslint-disable-next-line no-console
			console.error(error)
		}
	},
}

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
}
