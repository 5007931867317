import VuexORM from '@vuex-orm/core'
import User from '../../models/User'
import Teacher from '../../models/Teacher'
import Group from '../../models/Group'
import Course from '../../models/Course'
import Subject from '../../models/Subject'
import Lesson from '../../models/Lesson'
import Test from '../../models/Test'
import Question from '../../models/Question'
import Answer from '../../models/Answer'
import Result from '../../models/Result'

// Create a new instance of Database
const database = new VuexORM.Database()

// Register Models to Database
database.register(User)
database.register(Teacher)
database.register(Group)
database.register(Course)
database.register(Subject)
database.register(Lesson)
database.register(Test)
database.register(Question)
database.register(Answer)
database.register(Result)

export default VuexORM.install(database)