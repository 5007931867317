/* eslint-disable no-console */
import { apiFactory } from '../../api/apiFactory'

const usersApi = apiFactory.get('users')
const usersRolesApi = apiFactory.get('usersRoles')
const usersSubjectsApi = apiFactory.get('usersSubjects')
const usersCoursesApi = apiFactory.get('usersCourses')

const state = {
	users: [],
}

const getters = {
	users: ({ users }) => users,
}

const mutations = {
	SET_USERS(state, payload) {
		state.users = payload
	},
}

const actions = {
	async loadUsers({ commit }, params) {
		try {
			const { data } = await usersApi.getAll(params)
			commit('SET_USERS', data)
		} catch (error) {
			console.error(error)
		}
	},
	async updateRoles(state, { userid, roles}) {
		try {
			await usersRolesApi.update(userid, roles)
		} catch (error) {
			console.error(error)
		}
	},
	async updateSubjects(state, { userid, subjects}) {
		try {
			await usersSubjectsApi.update(userid, subjects)
		} catch (error) {
			console.error(error)
		}
	},
	async updateCourses(state, { userid, courses}) {
		try {
			await usersCoursesApi.update(userid, courses)
		} catch (error) {
			console.error(error)
		}
	},
}

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
}
