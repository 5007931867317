/* eslint-disable max-statements */
import api from '../../api/api'
import { apiFactory } from '../../api/apiFactory'
import User from '../../models/User'

const authApi = apiFactory.get('auth')

const state = {
	status: '',
	token: localStorage.getItem('token') || '',
	user: {},
}

const getters = {
	isLoggedIn: state => !!state.token,
	status: state => state.status,
	token: state => state.token,
	user: state => new User(state.user),
}

const mutations = {
	SET_STATUS(state, status) {
		state.status = status
	},
	SET_TOKEN(state, token) {
		state.token = token
	},
	SET_USER(state, user) {
		state.user = user
	},
	LOGOUT(state){
		state.status = ''
		state.token = ''
		state.user = {}
	},
}

const actions = {
	async login({ dispatch }, payload) {
		dispatch('loader/toggleLoader', true, { root: true })
		try {
			const { data } = await authApi.login(payload)
			const { token, user } = data
			dispatch('saveUser', user)
			dispatch('saveToken', token)
			dispatch('notification/notify', {
				text: 'Ура! Ти в системі 😎',
				color: 'success',
			}, { root: true })
			return true
		} catch (error) {
			dispatch('notification/notify', {
				text: 'Не вдалося увійти 😓',
				color: 'error',
			}, { root: true })
			return Promise.reject(error)
		} finally {
			dispatch('loader/toggleLoader', false, { root: true })
		}
	},
	
	async profile({ dispatch }, payload) {
		try {
			const { data } = await authApi.profile(payload)
			dispatch('saveUser', data)
			return data
		} catch (error) {
			dispatch('notify', {
				text: 'Не вдалося завантажити твої дані 😓',
				color: 'error',
			}, { root: true })
			throw error
		}
	},

	logout({ commit }) {
		try {
			commit('LOGOUT')
			localStorage.removeItem('token')
			localStorage.removeItem('user')
			delete api.defaults.headers.common['Authorization']
		} catch (error) {
			// eslint-disable-next-line no-console
			console.error(error)
		}
	},
	saveToken({ commit }, token) {
		commit('SET_TOKEN', token)
		localStorage.setItem('token', token)
		api.defaults.headers.common['Authorization'] = `Bearer ${token}`
	},
	saveUser({ commit }, user) {
		commit('SET_USER', user)
		localStorage.setItem('user', JSON.stringify(user))
	},
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
}