<template>
	<component v-if="name" :is="componentName" :name="name" :colors="colors" :size="size" :square="square"/>
</template>

<script>
import AvatarPixels from './AvatarPixels'
import AvatarBauhaus from './AvatarBauhaus'
import AvatarMarble from './AvatarMarble'
import AvatarRing from './AvatarRing'
import AvatarSunset from './AvatarSunset'
import AvatarBeam from './AvatarBeam'

const VARIANTS = ['pixels', 'bauhaus', 'ring', 'beam', 'sunset', 'marble']

export default {
	components: {
		AvatarPixels,
		AvatarBauhaus,
		AvatarMarble,
		AvatarSunset,
		AvatarRing,
		AvatarBeam,
	},
	props: {
		variant: {
			type: String,
			default: 'pixels',
			validator(value) {
				return VARIANTS.includes(value)
			},
		},
		name: {
			type: [String, null],
			default: 'Mary Baker',
		},
		colors: {
			type: Array,
			default: () => ['#FB4C53', '#06d6a0', '#FBD324', '#ff7f36', '#495159'],
		},
		size: { type: Number, default: 80 },
		square: { type: Boolean, default: false },
	},
	computed: {
		componentName() {
			if (!VARIANTS.includes(this.variant)) {
				return 'avatar-pixels'
			}
			return `avatar-${ this.variant}`
		},
	},
}
</script>
