const state = {
	show: false,
	color: '',
	text: '',
}

const getters = {
	show: state => state.show,
	color: state => state.color,
	text: state => state.text,
}

const mutations = {
	SET_SHOW(state, payload) {
		state.show = payload
	},
	SET_TEXT(state, payload) {
		state.text = payload
	},
	SET_COLOR(state, payload) {
		state.color = payload
	},
}

const actions = {
	notify: {
		root: true,
		handler({ commit }, payload) {
			commit('SET_TEXT', payload.text)
			commit('SET_COLOR', payload.color)
			commit('SET_SHOW', true)
		},
	},
	updateShow({ commit }, payload) {
		commit('SET_SHOW', payload)
	},
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
}