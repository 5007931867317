/* eslint-disable camelcase */
import ApiModel from './ApiModel'
import Question from './Question'

/**
 * Test model
 */
class Test extends ApiModel {

	static entity = 'tests'

	static apiResource = 'tests'

	static fields () {
		return {
			id: this.number(null).nullable(),
			order: this.number(null).nullable(),
			name: this.string(null).nullable(),
			is_active: this.boolean(false),
			category: this.attr({}).nullable(),
			questions: this.hasMany(Question, 'test_id'),
		}
	}

	/**
	 * Add new empty Question to Test
	 */
	addQuestion = () => {
		const data = {
			text: '',
			test_id: this.id,
		}

		return Question.insert({ data })
	}

	/**
	 * Get all Tests by Subject ID
	 * and insert response to Store
	 * @param {*} subjectid Subject ID
	 */
	static async fetchGetAllBySubject(subjectid) {
		const { data } = await this.api().getBySubject(subjectid)
		return this.create({ data })
	}

	/**
	 * Import Test as Xlsx
	 * @param {Number} testid Test ID
	 * @param {File} file File to import
	 */
	static import(testid, file) {
		return this.api().import(testid, file)
	}

	/**
	 * Export Test as Docx
	 * @param {Number} testid Subject ID
	 * @param {Object} params Query params
	 */
	static export(testid, params) {
		return this.api().export(testid, params)
	}
}

export default Test