<template>
	<v-app app>

		<component
			:is="layout">
		</component>

		<update-notify></update-notify>

		<notification></notification>
		<loader></loader>

	</v-app>
</template>

<script>
/* eslint-disable max-statements */
import { mapGetters, mapActions } from 'vuex'
import DefaultLayout from './layouts/DefaultLayout'
import AuthLayout from './layouts/AuthLayout'
import UpdateNotify from './components/UpdateNotify'

import Notification from './components/Notification'
import Loader from './components/Loader'

export default {
	components: {
		DefaultLayout,
		AuthLayout,
		UpdateNotify,
		Notification,
		Loader,
	},
	computed: {
		...mapGetters('auth', [
			'isLoggedIn',
		]),
		layout() {
			const layout = this.$route.meta.layout || 'default'
			return `${layout}-layout`
		},
	},
	methods: {
		...mapActions('auth', [
			'profile',
		]),
		activateLightMode(){
			const html = document.documentElement
			html.classList.remove('dark')
			this.$vuetify.theme.dark = false
		},
		activateDarkMode(){
			const html = document.documentElement
			html.classList.add('dark')
			this.$vuetify.theme.dark = true
		},
		// eslint-disable-next-line complexity
		setColorScheme() {
			const isDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches
			const isLightMode = window.matchMedia('(prefers-color-scheme: light)').matches
			const isNotSpecified = window.matchMedia('(prefers-color-scheme: no-preference)').matches
			const hasNoSupport = !isDarkMode && !isLightMode && !isNotSpecified

			// eslint-disable-next-line no-console
			console.warn(isDarkMode)

			window.matchMedia('(prefers-color-scheme: dark)').addListener(e => e.matches && this.activateDarkMode())
			window.matchMedia('(prefers-color-scheme: light)').addListener(e => e.matches && this.activateLightMode())

			if (isDarkMode) this.activateDarkMode()
			if (isLightMode) this.activateLightMode()
			if (isNotSpecified || hasNoSupport) {
				// eslint-disable-next-line no-console
				console.log('You specified no preference for a color scheme or your browser does not support it. I Schedule dark mode during night time.')
				const now = new Date()
				const hour = now.getHours()
				if (hour < 4 || hour >= 16) {
					this.activateDarkMode()
				}
			}
		},
	},
	mounted() {
		if (this.isLoggedIn) {
			this.profile()
		}

		this.setColorScheme()
	},
}
</script>

<style lang="scss">

@import './scss/main.scss';

.cursor-pointer {
	cursor: pointer;
}

.v-content,
.v-bottom-navigation {
    padding-bottom: constant(safe-area-inset-bottom); /* move footer up on iPhone X - iOS 11.0 */
    padding-bottom: env(safe-area-inset-bottom); /* move footer up on iPhone X - iOS 11.2 */
}

.v-bottom-navigation {
	height: auto !important;
}

.v-bottom-navigation .v-btn {
	height: 56px !important;
}

.theme--light.v-btn.v-btn--filled::before {
	opacity: 0.1;
}

.theme--light.v-btn.v-btn--filled:hover::before {
	opacity: 0.3;
}

</style>
