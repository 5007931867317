import Home from '../views/Home.vue'

import auth from '../middleware/auth'

export default [
	{
		path: '/',
		name: 'home',
		component: Home,
		meta: {
			middleware: [
				auth,
			],
		},
	},
	{
		path: '/index.html',
		redirect: '/',
	},
	{
		path: '/login',
		name: 'login',
		component: () => import('../views/Login.vue'),
		meta: {
			layout: 'auth',
		},
	},
	{
		path: '/users',
		name: 'users',
		component: () => import('../views/Users.vue'),
		meta: {
			middleware: [
				auth,
			],
		},
	},
	{
		path: '/users/:userid(\\d+)',
		name: 'user',
		component: () => import('../views/User.vue'),
		props: (route) => {
			return {
				userid: Number(route.params.userid),
			}
		},
		meta: {
			middleware: [
				auth,
			],
		},
	},
	{
		path: '/groups',
		name: 'groups',
		component: () => import('../views/Groups.vue'),
		meta: {
			middleware: [
				auth,
			],
		},
	},
	{
		path: '/lessons',
		name: 'lessons',
		component: () => import('../views/Lessons.vue'),
		meta: {
			middleware: [
				auth,
			],
		},
	},
	{
		path: '/lessons/:id(\\d+)',
		name: 'lesson',
		component: () => import('../views/Lesson.vue'),
		props: ({ params }) => ({
			id: Number(params.id),
		}),
		meta: {
			middleware: [
				auth,
			],
		},
	},
	{
		path: '/results/:id(\\d+)',
		name: 'result',
		component: () => import('../views/Result.vue'),
		props: ({ params }) => ({
			id: Number(params.id),
		}),
		meta: {
			middleware: [
				auth,
			],
		},
	},
	{
		path: '/tests',
		name: 'tests',
		component: () => import('../views/Tests.vue'),
		meta: {
			middleware: [
				auth,
			],
		},
	},
	{
		path: '/modules',
		name: 'modules',
		component: () => import('../views/Modules.vue'),
		meta: {
			middleware: [
				auth,
			],
		},
	},
	{
		path: '/modules/:moduleid(\\d+)',
		name: 'module',
		component: () => import('../views/Module.vue'),
		props: (route) => ({
			moduleid: Number(route.params.moduleid),
		}),
		meta: {
			middleware: [
				auth,
			],
		},
	},
	{
		path: '/schedule',
		name: 'schedule',
		component: () => import('../views/Schedule.vue'),
		meta: {
			middleware: [
				auth,
			],
		},
	},
	{
		path: '/lectures/:lectureid(\\d+)',
		name: 'lecture',
		component: () => import('../views/Lecture.vue'),
		props: (route) => ({
			lectureid: Number(route.params.lectureid),
		}),
		meta: {
			middleware: [
				auth,
			],
		},
	},
	{
		path: '/teachers',
		name: 'teachers',
		component: () => import('../views/Teachers.vue'),
		meta: {
			middleware: [
				auth,
			],
		},
	},
	{
		path: '/courses',
		name: 'courses',
		component: () => import('../views/Courses.vue'),
		meta: {
			middleware: [
				auth,
			],
		},
	},
	{
		path: '/courses/:id(\\d+)',
		name: 'course',
		component: () => import('../views/Course.vue'),
		props: ({ params }) => ({
			id: Number(params.id),
		}),
		meta: {
			middleware: [
				auth,
			],
		},
	},
	{
		path: '/subjects',
		name: 'subjects',
		component: () => import('../views/Subjects.vue'),
		meta: {
			middleware: [
				auth,
			],
		},
	},
	{
		path: '/subjects/:id(\\d+)',
		name: 'subject',
		component: () => import('../views/Subject.vue'),
		props: ({ params }) => ({
			id: Number(params.id),
		}),
		meta: {
			middleware: [
				auth,
			],
		},
	},
	{
		path: '/tests/:id(\\d+)',
		name: 'test',
		component: () => import('../views/Test.vue'),
		props: ({ params }) => ({
			id: Number(params.id),
		}),
		meta: {
			middleware: [
				auth,
			],
		},
	},
	{
		path: '/404',
		name: '404',
		component: () => import('../views/404.vue'),
	},
]