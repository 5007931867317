import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/store'
import './registerServiceWorker'
import vuetify from './plugins/vuetify'
import './plugins/vee-validate'
import PortalVue from 'portal-vue'
import edgeCheck from './plugins/vue-edge-check'
import SweetAlertVuetify from './plugins/SweetAlertVuetify'
import './filters'
import 'windi.css'

import { ValidationObserver } from 'vee-validate'
import AppConfirm from './components/AppConfirm'
import VExpandingField from './components/Inputs/VExpandingField'
import VvTextField from './components/Inputs/VvTextField'
import VvAutocomplete from './components/Inputs/VvAutocomplete'
import VvTextarea from './components/Inputs/VvTextarea'
import VvCheckbox from './components/Inputs/VvCheckbox'
import FileDownloader from './components/FileDownloader'

Vue.config.productionTip = false

Vue.component('validation-observer', ValidationObserver)
Vue.component('app-confirm', AppConfirm)
Vue.component('v-expanding-field', VExpandingField)
Vue.component('vv-text-field', VvTextField)
Vue.component('vv-autocomplete', VvAutocomplete)
Vue.component('vv-textarea', VvTextarea)
Vue.component('vv-checkbox', VvCheckbox)
Vue.component('file-downloader', FileDownloader)

Vue.use(edgeCheck)
Vue.use(PortalVue)
Vue.use(SweetAlertVuetify, vuetify)

new Vue({
	router,
	store,
	vuetify,
	render: h => h(App),
}).$mount('#app')
