import api from './api'

const resource = '/groups'

export default {
	
	/**
	 * Get all Groups
	 */
	getAll() {
		return api.get(`${resource}`)
	},

	/**
	 * Get Group by id
	 * @param {Number} groupid
	 */
	get(groupid) {
		return api.get(`${resource}/${groupid}`)
	},

	/**
	 * Create Group Model
	 * @param {Object} payload Group Model
	 */
	create(payload) {
		return api.post(`${resource}`, payload)
	},

	/**
	 * Update Group Model
	 * @param {Object} payload Group Model
	 */
	update(payload) {
		return api.put(`${resource}/${payload.id}`, payload)
	},

	/**
	 * Delete Group Model
	 * @param {Number} groupid
	 */
	delete(groupid) {
		return api.delete(`${resource}/${groupid}`)
	},
	
}