import api from './api'

const resource = 'results'

export default {
	
	/**
	 * Get all Results
	 */
	getAll() {
		return api.get(`${resource}`)
	},

	/**
	 * Get all Results by User
	 * @param {Number} userid User id
	 */
	getAllByUser(userid) {
		return api.get(`/users/${userid}/${resource}`)
	},

	/**
	 * Get Result by id
	 * @param {Number} resultid
	 */
	get(resultid) {
		return api.get(`${resource}/${resultid}`)
	},

	/**
	 * Create Result Model
	 * @param {Object} payload Result Model
	 */
	create(payload) {
		return api.post(`${resource}`, payload)
	},

	/**
	 * Update Result Model
	 * @param {Object} payload Result Model
	 */
	update(payload) {
		return api.put(`${resource}/${payload.id}`, payload)
	},

	/**
	 * Delete Result Model
	 * @param {Object} payload Result Model
	 */
	delete(payload) {
		return api.put(`${resource}/${payload.id}`)
	},
	
}