/* eslint-disable camelcase */
import { maxBy } from 'lodash'

import ApiModel from './ApiModel'

/**
 * User model
 */
class User extends ApiModel {

	static entity = 'users'

	static apiResource = 'users'

	static fields () {
		return {
			id: this.number(null).nullable(),
			name: this.string(null).nullable(),
			surname: this.string(null).nullable(),
			full_name: this.string(null).nullable(),
			email: this.string(null).nullable(),
			tel: this.string(null).nullable(),
			roles: this.attr([]),
			permissions: this.attr([]),
			groups: this.attr([]),
			user_courses: this.attr([]),
			user_subjects: this.attr([]),
			teached_subjects: this.attr([]),
			results: this.attr([]),
			latest_results: this.attr([]),
			created_at: this.string(null).nullable(),
			updated_at: this.string(null).nullable(),
		}
	}

	/**
     * Get role level of a user
     *
     * @return {Number}
     */
	get level() {
		const role = maxBy(this.roles, 'level')
		return role?.level
	}

	/**
	 * Check if the user has a permission
	 *
	 * @param {Number|String} permission permission ID or slug
	 * @returns {Boolean}
	 */
	can(permission) {
		return this.permissions.some(({ id, slug }) => {
			return permission === id || permission === slug
		})
	}

	/**
	 * Check if user has specific role
	 *
	 * @param {String} role role name
	 * @returns {Boolean}
	 */
	is(role) {
		return !!this.roles?.find(r => r?.slug === role)
	}

	/**
	 * Get latest tests results for User
	 * in CSV format
	 * @param {Number} userid User ID
	 */
	static report(userid) {
		return this.api().report(userid)
	}
}

export default User