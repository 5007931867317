/* eslint-disable camelcase */
import ApiModel from './ApiModel'

/**
 * Answer model
 */
class Answer extends ApiModel {

	static entity = 'answers'

	static apiResource = 'answers'

	static fields () {
		return {
			id: this.number(null).nullable(),
			order: this.number(null).nullable(),
			text: this.string(null).nullable(),
			image: this.string(null).nullable(),
			is_correct: this.boolean(false).nullable(),
			correct_pair:this.number(null).nullable(),
		}
	}

}

export default Answer
