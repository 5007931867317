<template>
	<v-navigation-drawer
		v-model="show"
		:mini-variant="$vuetify.breakpoint.mdAndUp"
		:expand-on-hover="$vuetify.breakpoint.mdAndUp"
		floating
		app>
		<v-list
			dense
			nav>
			<v-list-item class="px-0">
				<v-list-item-avatar rounded>
					<v-img
						src="/img/brames_logo.svg">
					</v-img>
				</v-list-item-avatar>

				<v-list-item-title>
					brames
					<v-chip
						class="ml-2"
						x-small>
						v{{ version }}
					</v-chip>
				</v-list-item-title>
			</v-list-item>

			<v-list-item-group
				color="primary">

				<v-list-item
					v-for="(item, key) in items"
					:key="key"
					:to="item.route"
					:exact="item.exact"
					link>
					<v-list-item-icon>
						<v-icon>{{ item.icon }}</v-icon>
					</v-list-item-icon>

					<v-list-item-content>
						<v-list-item-title>
							{{ item.title }}
						</v-list-item-title>
					</v-list-item-content>
				</v-list-item>

			</v-list-item-group>
		</v-list>

	</v-navigation-drawer>
</template>

<script>
import { version } from '../../package.json'

export default {
	data: () => ({
		version,
	}),
	computed: {
		show: {
			get() {
				return this.$store?.state.sidebar?.show
					|| this.$vuetify.breakpoint.mdAndUp
			},
			set(value) {
				this.$store.commit('sidebar/SET_SHOW', value)
			},
		},
		items() {
			return [
				{
					title: 'Головна',
					route: {
						name: 'home',
					},
					icon: 'mdi-home-variant',
					exact: true,
				},
				{
					title: 'Учні',
					route: {
						name: 'users',
					},
					icon: 'mdi-account',
				},
				{
					title: 'Групи',
					route: {
						name: 'groups',
					},
					icon: 'mdi-account-group',
				},
				{
					title: 'Курси',
					route: {
						name: 'courses',
					},
					icon: 'mdi-television',
				},
				{
					title: 'Тести',
					route: {
						name: 'subjects',
					},
					icon: 'mdi-text-box-check',
				},
			]
		},
	},
}
</script>
