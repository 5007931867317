import api from './api'

const resource = '/answers'

export default {

	/**
	 * Delete Answer Model
	 * @param {Number} id Answer Model ID
	 */
	delete(id) {
		return api.delete(`${resource}/${id}`)
	},
}
