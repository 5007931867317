/* eslint-disable no-console */
import { apiFactory } from '../../api/apiFactory'

const testsApi = apiFactory.get('tests')

const state = {
	tests: [],
}

const getters = {
	tests: state => state.tests,
}

const mutations = {
	SET_TESTS(state, payload) {
		state.tests = payload
	},
	EDIT_CASE(state, payload) {
		const item = state.tests.find(item => item.id === payload.id)
		Object.assign(item, payload)
	},
}

const actions = {
	async loadTests({ commit }) {
		try {
			const { data } = await testsApi.getAll()
			commit('SET_TESTS', data)
		} catch (error) {
			console.error(error)
		}
	},
	// async updateTest({ commit }, payload) {
	// 	try {
	// 		const { data } = await testsApi.update(payload)
	// 		// commit('EDIT_CASE', data)
	// 	} catch (error) {
	// 		console.error(error)
	// 	}
	// },
}

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
}
