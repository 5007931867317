const state = {
	loading: false,
}

const getters = {
	loading: state => state.loading,
}

const mutations = {
	SET_LOADING(state, payload) {
		state.loading = payload
	},
}

const actions = {
	toggleLoader({ commit }, payload) {
		commit('SET_LOADING', payload)
	},
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
}