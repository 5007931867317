import api from './api'

const resource = '/users'

export default {

	getAll(params) {
		return api.get(`${resource}`, {
			params,
		})
	},
	
	get(userid) {
		return api.get(`${resource}/${userid}`)
	},

	/**
	 * Update User model
	 * @param {Question} payload
	 */
	update(payload) {
		return api.put(`${resource}/${payload.id}`, payload)
	},

	report(userid) {
		return api.get(`${resource}/${userid}/report`, {
			responseType: 'blob',
		})
	},
	
}