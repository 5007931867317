/* eslint-disable camelcase */
import ApiModel from './ApiModel'

/**
 * Course model
 */
class Course extends ApiModel {

	static entity = 'courses'

	static apiResource = 'courses'

	static fields () {
		return {
			id: this.number(null).nullable(),
			name: this.string(null).nullable(),
			author_id: this.number(null).nullable(),
			author: this.attr({}).nullable(),
			subjects: this.attr([]),
		}
	}
}

export default Course