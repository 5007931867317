import api from './api'

const resource = '/courses'

export default {

	getAll() {
		return api.get(`${resource}`)
	},

	get(id) {
		return api.get(`${resource}/${id}`)
	},
}