import { parseISO, format, formatDistanceToNow } from 'date-fns'
import { uk } from 'date-fns/locale'

export default {

	/**
	 * Format date in general date format
	 * e.g. 31 серпня 2016
	 *
	 * @param {String} value Date in ISO format
	 */
	date: (value) => {
		return value ? format(parseISO(value), 'd MMMM y', { locale: uk })
			: value
	},

	/**
	 * Format datetime in general date format
	 * e.g. 31 серпня 2016 14:00
	 *
	 * @param {String} value Date in ISO format
	 */
	datetime: (value) => {
		return value ? format(parseISO(value), 'd MMMM y HH:mm', { locale: uk })
			: value
	},

	/**
	 * Format time distance to now in human readable format
	 * @param {String} value Date in ISO format
	 */
	formatDistanceToNow: (value) => {
		return value
			? formatDistanceToNow(parseISO(value), {
				addSuffix: true,
				locale: uk,
			})
			: value
	},

}