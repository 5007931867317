import api from './api'

const resource = '/tests'

export default {

	/**
	 * Get all tests
	 */
	getAll(params) {
		return api.get(`${resource}`, {
			params,
		})
	},
	
	/**
	 * Get tests by subject id
	 * @param {Number} subjectid
	 */
	getBySubject(subjectid) {
		return api.get(`/subjects/${subjectid}/tests`)
	},

	/**
	 * Get Test by ID
	 * @param {Number} testid Test ID
	 */
	get(testid) {
		return api.get(`${resource}/${testid}`)
	},

	checkTest(testid, payload) {
		return api.post(`${resource}/${testid}/check`, payload)
	},

	/**
	 * Create Test Model
	 * @param {Object} payload Test Model
	 */
	create(payload) {
		return api.post(`${resource}`, payload)
	},

	/**
	 * Update test model
	 * @param {Test} payload
	 */
	update(payload) {
		return api.put(`${resource}/${payload.id}`, payload)
	},

	/**
	 * Import Test as Docx
	 * @param {Number} testid Test ID
	 * @param {File} file File to import
	 */
	import(testid, file) {
		const formData = new FormData()
		formData.append('file', file)

		return api.post(`${resource}/${testid}/import`, formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		})
	},

	/**
	 * Export Test as Docx
	 * @param {Number} testid Test ID
	 * @param {Object} params Query params
	 */
	export(testid, params) {
		return api.get(`${resource}/${testid}/export`, {
			responseType: 'blob',
			params,
		})
	},
}