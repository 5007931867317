import { Model } from '@vuex-orm/core'
import { apiFactory } from '../api/apiFactory'

/**
 * ApiModel
 */
class ApiModel extends Model {

	/**
	 * API Resource Name to get API from API Factory
	 */
	static apiResource = ''

	/**
	 * API with all methods methods
	 */
	static api() {
		return apiFactory.get(this.apiResource)
	}

	/**
	 * Get all models from API and insert response to Store
	 */
	static async fetchGetAll(params) {
		const { data } = await this.api().getAll(params)
		return this.create({ data })
	}

	/**
	 * Get one model from API and insert response to Store
	 * @param {Number} id Model ID
	 */
	static async fetchGet(id) {
		const { data } = await this.api().get(id)
		return this.insert({ data })
	}

	/**
	 * Determine to create or update model and dispatch method
	 * @param {Object} payload Model
	 */
	static fetchCreateOrUpdate(payload) {
		if (payload[this.primaryKey]) {
			return this.fetchUpdate(payload)
		} else {
			return this.fetchCreate(payload)
		}
	}

	/**
	 * Send model to POST API and insert response to Store
	 * @param {Object} payload Model
	 */
	static async fetchCreate(payload) {
		const { data } = await this.api().create(payload)
		return this.insert({ data })
	}

	/**
	 * Send model to PUT API and insert response to Store
	 * @param {Object} payload Model
	 */
	static async fetchUpdate(payload) {
		const { data } = await this.api().update(payload)
		return this.insert({ data })
	}

	/**
	 * Delete model by DELETE API and delete from Store
	 * @param {Number} id Model ID
	 */
	static async fetchDelete(id) {
		await this.api().delete(id)
		return this.delete(id)
	}

}

export default ApiModel
