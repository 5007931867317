import Vue from 'vue'
import Router from 'vue-router'
import routes from './routes'
import store from '../store/store'
import { middlewarePipeline } from '../middleware'

Vue.use(Router)

const router = new Router({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
	scrollBehavior (to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition
		} else {
			return { x: 0, y: 0 }
		}
	},
})

router.beforeEach((to, from, next) => {
	if (!to.meta.middleware) {
		return next()
	}
	const { middleware } = to.meta

	const context = {
		to,
		from,
		next,
		store,
	}

	return middleware[0]({
		...context,
		next: middlewarePipeline(context, middleware, 1),
	})
})

export default router