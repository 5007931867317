import { required, email, min } from 'vee-validate/dist/rules'
import { configure, extend } from 'vee-validate'

const config = {
	mode: 'eager',
	defaultMessage: 'Поле {_field_} заповнено неправильно',
}

configure(config)

extend('required', {
	...required,
	message: "Це поле обов'язкове",
})

extend('min', {
	...min,
	message: 'Це поле має мати довжину принаймні {length} символів',
})

extend('email', {
	...email,
	message: 'Це поле має бути валідним емейлом',
})