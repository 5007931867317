import authApi from './authApi'
import subjectsApi from './subjectsApi'
import coursesApi from './coursesApi'
import modulesApi from './modulesApi'
import lessonsApi from './lessonsApi'
import usersApi from './usersApi'
import usersRolesApi from './usersRolesApi'
import usersSubjectsApi from './usersSubjectsApi'
import usersCoursesApi from './usersCoursesApi'
import usersLessonsApi from './usersLessonsApi'
import groupsApi from './groupsApi'
import rolesApi from './rolesApi'
import testsApi from './testsApi'
import questionsApi from './questionsApi'
import answersApi from './answersApi'
import resultsApi from './resultsApi'
import filesApi from './filesApi'
import lecturesApi from './lecturesApi'
import lectureUsersApi from './lectureUsersApi'

const apis = {
	auth: authApi,
	subjects: subjectsApi,
	courses: coursesApi,
	modules: modulesApi,
	lessons: lessonsApi,
	users: usersApi,
	usersRoles: usersRolesApi,
	usersSubjects: usersSubjectsApi,
	usersCourses: usersCoursesApi,
	usersLessons: usersLessonsApi,
	groups: groupsApi,
	roles: rolesApi,
	tests: testsApi,
	questions: questionsApi,
	answers: answersApi,
	results: resultsApi,
	files: filesApi,
	lectures: lecturesApi,
	lectureUsers: lectureUsersApi,
}

export const apiFactory = {
	get: name => apis[name],
}