/* eslint-disable camelcase */
import api from './api'

const resource = '/users'

export default {

	getAll(userid) {
		return api.get(`${resource}/${userid}/lessons`)
	},

	create({ userid, lesson_id }) {
		return api.post(`${resource}/${userid}/lessons`, [ lesson_id ])
	},

	update(userid, lesson_id, payload) {
		return api.put(`${resource}/${userid}/lessons/${lesson_id}`, payload)
	},

	delete({ userid, lesson_id }) {
		return api.delete(`${resource}/${userid}/lessons/${lesson_id}`)
	},
}