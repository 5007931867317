var render = function render(){var _vm=this,_c=_vm._self._c;return _c('svg',{attrs:{"viewBox":`0 0 ${_vm.viewboxSize} ${_vm.viewboxSize}`,"fill":"none","xmlns":"http://www.w3.org/2000/svg","width":_vm.size,"height":_vm.size}},[_c('mask',{attrs:{"id":"mask__beam","maskUnits":"userSpaceOnUse","x":"0","y":"0","width":_vm.viewboxSize,"height":_vm.viewboxSize}},[_c('rect',{attrs:{"width":_vm.viewboxSize,"height":_vm.viewboxSize,"rx":_vm.square ? _vm.undefinded : _vm.viewboxSize / 2,"fill":"#fff"}})]),_c('g',{attrs:{"mask":"url(#mask__beam)","fill":"transparent"}},[_c('rect',{attrs:{"width":_vm.viewboxSize,"height":_vm.viewboxSize,"fill":_vm.data.backgroundColor}}),_c('rect',{attrs:{"x":"0","y":"0","width":_vm.viewboxSize,"height":_vm.viewboxSize,"transform":`translate(${_vm.data.wrapperTranslateX} ${
					_vm.data.wrapperTranslateY
				}) rotate(${_vm.data.wrapperRotate} ${_vm.viewboxSize / 2} ${
					_vm.viewboxSize / 2
				}) scale(${_vm.data.wrapperScale})`,"fill":_vm.data.wrapperColor,"rx":_vm.data.isCircle ? _vm.viewboxSize : _vm.viewboxSize / 6}}),_c('g',{attrs:{"transform":`translate(${_vm.data.faceTranslateX} ${
					_vm.data.faceTranslateY
				}) rotate(${_vm.data.faceRotate} ${_vm.viewboxSize / 2} ${_vm.viewboxSize / 2})`}},[(_vm.data.isMonthOpen)?_c('path',{attrs:{"d":`M15 ${19 + _vm.data.mouthSpread} c2 1
        4 1 6 0`,"stroke":_vm.data.faceColor,"fill":"none","strokeLinecap":"round"}}):_c('path',{attrs:{"d":`M13, ${19 + _vm.data.mouthSpread} a1,0.75 0 0,0 10,0`,"fill":_vm.data.faceColor}}),_c('rect',{attrs:{"x":14 + _vm.data.eyeSpread,"y":"14","width":"1.5","height":"2","rx":"1","stroke":"none","fill":_vm.data.faceColor}}),_c('rect',{attrs:{"x":20 + _vm.data.eyeSpread,"y":"14","width":"1.5","height":"2","rx":"1","stroke":"none","fill":_vm.data.faceColor}})])])])
}
var staticRenderFns = []

export { render, staticRenderFns }