import { apiFactory } from '../../api/apiFactory'

const coursesApi = apiFactory.get('courses')

const state = {
	courses: [],
}

const getters = {
	courses: ({ courses }) => courses,
}

const mutations = {
	SET_COURSES(state, payload) {
		state.courses = payload
	},
}

const actions = {
	async loadCourses({ commit }) {
		try {
			const { data } = await coursesApi.getAll()
			commit('SET_COURSES', data)
		} catch (error) {
			// eslint-disable-next-line no-console
			console.error(error)
		}
	},
}

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
}
