/* eslint-disable camelcase */
import ApiModel from './ApiModel'

/**
 * Question model
 */
class Question extends ApiModel {

	static entity = 'questions'

	static apiResource = 'questions'

	static fields () {
		return {
			id: this.number(null).nullable(),
			order: this.number(null).nullable(),
			text: this.string(null).nullable(),
			type: this.number(null).nullable(),
			image: this.string(null).nullable(),
			images: this.attr([]).nullable(),
			test_id: this.attr(null).nullable(),
			subquestions: this.attr([]).nullable(),
			answers: this.attr([]).nullable(),
		}
	}

	/**
	 * Add new empty answer to Question
	 */
	addAnswer = () => {
		this.answers.push({
			text: '',
		})
	}

	/**
	 * Attach images to question
	 * @param {Number} id Question ID
	 * @param {File} file File to import
	 */
	static createImage(id, file) {
		return this.api().createImage(id, file)
	}

	/**
	 * Remove images from question
	 * @param {Number} id Question ID
	 * @param {Number} imageId Image Media ID
	 * @param {File} file File to import
	 */
	static deleteImage(id, imageId) {
		return this.api().deleteImage(id, imageId)
	}

}

export default Question
