var render = function render(){var _vm=this,_c=_vm._self._c;return _c('svg',{attrs:{"viewBox":`0 0 ${_vm.viewboxSize} ${_vm.viewboxSize}`,"fill":"none","xmlns":"http://www.w3.org/2000/svg","width":_vm.size,"height":_vm.size}},[_c('mask',{attrs:{"id":"mask__bauhaus","maskUnits":"userSpaceOnUse","x":"0","y":"0","width":_vm.viewboxSize,"height":_vm.viewboxSize}},[_c('rect',{attrs:{"width":_vm.viewboxSize,"height":_vm.viewboxSize,"rx":_vm.square ? _vm.undefinded : _vm.viewboxSize / 2,"fill":"#fff"}})]),_c('g',{attrs:{"mask":"url(#mask__bauhaus)"}},[_c('rect',{attrs:{"width":_vm.viewboxSize,"height":_vm.viewboxSize,"fill":_vm.properties[0].color}}),_c('rect',{attrs:{"x":(_vm.viewboxSize - 60) / 2,"y":(_vm.viewboxSize - 20) / 2,"width":_vm.viewboxSize,"height":_vm.properties[1].isSquare ? _vm.viewboxSize : _vm.viewboxSize / 8,"fill":_vm.properties[1].color,"transform":`translate(${_vm.properties[1].translateX} ${
				_vm.properties[1].translateY
			}) rotate(${_vm.properties[1].rotate} ${_vm.viewboxSize / 2} ${
				_vm.viewboxSize / 2
			})`}}),_c('circle',{attrs:{"cx":_vm.viewboxSize / 2,"cy":_vm.viewboxSize / 2,"fill":_vm.properties[2].color,"r":_vm.viewboxSize / 5,"transform":`translate(${_vm.properties[2].translateX} ${_vm.properties[2].translateY})`}}),_c('line',{attrs:{"x1":"0","y1":_vm.viewboxSize / 2,"x2":_vm.viewboxSize,"y2":_vm.viewboxSize / 2,"strokeWidth":"2","stroke":_vm.properties[3].color,"transform":`translate(${_vm.properties[3].translateX} ${
				_vm.properties[3].translateY
			}) rotate(${_vm.properties[3].rotate} ${_vm.viewboxSize / 2} ${
				_vm.viewboxSize / 2
			})`}})])])
}
var staticRenderFns = []

export { render, staticRenderFns }