<template>
	<v-btn
		color="success"
		:loading="loading"
		text
		@click="download">

		<slot>Завантажити</slot>

	</v-btn>
</template>

<script>
import Blob from 'blob'
import { saveAs } from 'file-saver'

export default {
	props: {
		name: {
			type: String,
			required: true,
		},
		apiCall: {
			type: Function,
			required: true,
		},
	},
	data: () => ({
		loading: false,
	}),
	methods: {
		/**
		 * Fetch data as blob and download it
		 */
		async download() {
			try {
				this.loading = true
				// const { data } = await filesApi.get(this.id)
				const { data } = await this.apiCall()
				const blob = new Blob([data]) // NOTE: it's fallback for Blob API
				saveAs(blob, `${this.name}`)
			} catch (error) {
				console.error(error)
			} finally {
				this.loading = false
			}
		},
	},
}
</script>