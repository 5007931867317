import api from './api'

const resource = '/modules'

export default {

	/**
	 * Get list of the modules
	 *
	 * @returns {Array} modules
	 */
	getAll() {
		return api.get(`${resource}`)
	},

	/**
	 * Get model
	 *
	 * @param {Number} id module id
	 * @returns {Object} module
	 */
	get(id) {
		return api.get(`${resource}/${id}`)
	},
	
	/**
	 * Create new module
	 *
	 * @param {Object} payload module
	 * @returns {Object} module
	 */
	create(payload) {
		return api.post(`${resource}`, payload)
	},

	/**
	 * Update module
	 *
	 * @param {Object} payload module
	 * @returns {Object} module
	 */
	update(payload) {
		return api.put(`${resource}/${payload.id}`, payload)
	},

	/**
	 * Delete module
	 *
	 * @param {Object} payload module
	 * @returns {Object} module
	 */
	delete(payload) {
		return api.delete(`${resource}/${payload.id}`)
	},
}