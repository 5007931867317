import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth'
import sidebar from './modules/sidebar'
import notification from './modules/notification'
import loader from './modules/loader'
import subjects from './modules/subjects'
import courses from './modules/courses'
import users from './modules/users'
import user from './modules/user'
import roles from './modules/roles'
import tests from './modules/tests'

// Plugins
import VuexORM from './plugins/vuex-orm'

Vue.use(Vuex)

export default new Vuex.Store({
	modules: {
		auth,
		sidebar,
		notification,
		loader,
		subjects,
		courses,
		users,
		user,
		roles,
		tests,
	},
	state: {
		darkMode: false,
	},
	getters: {
		darkMode: state => state.darkMode,
	},
	mutations: {
		SET_DARK_MODE(state, payload) {
			state.darkMode = payload
		},
	},
	actions: {
		toogleDarkMode({ commit }, val=Boolean) {
			commit('SET_DARK_MODE', val)
		},
	},
	plugins: [
		VuexORM,
	],
})
