import api from './api'

const resource = '/subjects'

export default {

	getAll() {
		return api.get(`${resource}`)
	},

	get(id) {
		return api.get(`${resource}/${id}`)
	},
	
	create(payload) {
		return api.post(resource, payload)
	},
}