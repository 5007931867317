import router from '../router'

// export function errorHandler(error) {
// 	if(error.response.status === 401) {
// 		router.push({ name: 'login' })
// 	}
// 	return Promise.reject({
// 		...error
// 	})
// }

import api from './api'


// for multiple requests
let isRefreshing = false
let failedQueue = []

const processQueue = (error, token = null) => {
	failedQueue.forEach(prom => {
		if (error) {
			prom.reject(error)
		} else {
			prom.resolve(token)
		}
	})

	failedQueue = []
}

export function errorHandler(error) {

	const originalRequest = error.config

	// if (error.response.status === 401 && !originalRequest._retry) {
	if (error.response.status === 401) {

		if (isRefreshing) {
			return new Promise(function (resolve, reject) {
				failedQueue.push({
					resolve,
					reject,
				})
			}).then(token => {
				originalRequest.headers['Authorization'] = `Bearer ${token}`
				return api(originalRequest)
			}).catch(err => {
				return Promise.reject(err)
			})
		}

		originalRequest._retry = true
		isRefreshing = true

		const refreshToken = window.localStorage.getItem('token')
		api.defaults.headers.common['Authorization'] = `Bearer${refreshToken}`
		return new Promise(function (resolve, reject) {
			api.post('/refresh')
				.then(({
					data,
				}) => {
					window.localStorage.setItem('token', data.token)
					api.defaults.headers.common['Authorization'] = `Bearer ${data.token}`
					originalRequest.headers['Authorization'] = `Bearer ${data.token}`
					processQueue(null, data.token)
					resolve(api(originalRequest))
				})
				.catch((err) => {
					processQueue(err, null)
					reject(err)
					router.push('/login')
				})
				.then(() => {
					isRefreshing = false
				})
		})
	}

	return Promise.reject(error)
}