<template>
	<div>

		<AppBar></AppBar>

		<AppSidebar></AppSidebar>

		<v-main>
			<router-view></router-view>
		</v-main>

	</div>
</template>

<script>
import AppBar from '../components/AppBar'
import AppSidebar from '../components/AppSidebar'

export default {
	components: {
		AppBar,
		AppSidebar,
	},
}
</script>
