<template>
	<div>
		<section v-if="user.can('view.users')">
			<v-container class="d-flex justify-space-between align-center">
				<h2>Учні</h2>
				<v-btn
					:to="{ name: 'users' }"
					text
					color="primary"
					small
					class="text-none">
					далі
					<v-icon>mdi-chevron-right</v-icon>
				</v-btn>
			</v-container>

			<v-swiper :options="{ freeMode: true, slidesPerView: 'auto', }" class="my-5">
				<router-link
					v-for="user in users"
					:key="user.id"
					class="swiper-slide mx-3 d-flex flex-column align-center text-center"
					:to="{
						name: 'user',
						params: {
							userid: user.id,
						}
					}">
					<v-avatar
						color="grey lighten-2"
						size="80"
						:class="{'v-avatar--outlined border--success': user.roles.length > 0}">
						<Avatar
							:name="user.full_name"
							variant="beam">
						</Avatar>
						<!-- <v-icon>mdi-account-circle</v-icon> -->
					</v-avatar>
					<small class="text-none">{{ user.name }} {{ user.surname }}</small>
				</router-link>
			</v-swiper>
		</section>

		<!-- <bottom-sheet></bottom-sheet> -->

	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Avatar from '../components/BoringAvatars'
import VSwiper from '../components/VSwiper'
// import BottomSheet from '../components/BottomSheet'

export default {
	components: {
		Avatar,
		VSwiper,
		// BottomSheet,
	},
	data: () => ({
		routes: [
			{
				title: 'Учні',
				path: {
					name: 'users',
				},
			},
			{
				title: 'Тести',
				path: {
					name: 'tests',
				},
			},
		],
	}),
	computed: {
		...mapGetters('auth', [
			'user',
		]),
		...mapGetters('users', [
			'users',
		]),
	},
	methods: {
		...mapActions('users', [
			'loadUsers',
		]),
	},
	async mounted() {
		await this.loadUsers({
			limit: 10,
			sort: 'asc',
		})
	},
}
</script>

<style>

.grid {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(8rem, 1fr));
	grid-auto-rows: 1fr;
	grid-gap: 2em;
}

.grid::before {
  content: '';
  width: 0;
  padding-bottom: 100%;
  grid-row: 1 / 1;
  grid-column: 1 / 1;
}

.grid > *:first-child {
  grid-row: 1 / 1;
  grid-column: 1 / 1;
}

.v-avatar--outlined {
	box-sizing: border-box;
	border: 2px solid;
}

.v-application .v-avatar.v-avatar--outlined.border--success {
	border-color: var(--v-success-base) !important;
}

</style>
