import api from './api'

const resource = '/questions'

export default {

	/**
	 * Get Question by id
	 * @param {Number} questionId Question Model ID
	 */
	get(questionId) {
		return api.get(`${resource}/${questionId}`)
	},

	/**
	 * Create Question model
	 * @param {Question} payload Question Model
	 */
	create(payload) {
		return api.post(`${resource}`, payload)
	},

	/**
	 * Update Question model
	 * @param {Question} payload
	 */
	update(payload) {
		return api.put(`${resource}/${payload.id}`, payload)
	},

	/**
	 * Delete Question Model
	 * @param {Number} id Question Model ID
	 */
	delete(id) {
		return api.delete(`${resource}/${id}`)
	},

	/**
	 * Create Image attached to Question
	 * @param {Number} id Question Model ID
	 * @param {File} file File to import
	 */
	createImage(id, file) {
		const formData = new FormData()
		formData.append('images', file)

		return api.post(`${resource}/${id}/images`, formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		})
	},

	/**
	 * Delete Image attached to Question
	 * @param {Number} id Question Model ID
	 * @param {Number} imageId Media Model ID
	 */
	deleteImage(id, imageId) {
		return api.delete(`${resource}/${id}/images/${imageId}`)
	},

}
