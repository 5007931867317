var render = function render(){var _vm=this,_c=_vm._self._c;return _c('svg',{attrs:{"viewBox":`0 0 ${_vm.viewboxSize} ${_vm.viewboxSize}`,"fill":"none","xmlns":"http://www.w3.org/2000/svg","width":_vm.size,"height":_vm.size}},[_c('mask',{attrs:{"id":"mask__marble","maskUnits":"userSpaceOnUse","x":"0","y":"0","width":_vm.viewboxSize,"height":_vm.viewboxSize}},[_c('rect',{attrs:{"width":_vm.viewboxSize,"height":_vm.viewboxSize,"rx":_vm.square ? _vm.undefinded : _vm.viewboxSize / 2,"fill":"#fff"}})]),_c('g',{attrs:{"mask":"url(#mask__marble)"}},[_c('rect',{attrs:{"width":_vm.viewboxSize,"height":_vm.viewboxSize,"rx":2,"fill":_vm.properties[0].color}}),_c('path',{attrs:{"filter":"url(#prefix__filter0_f)","d":"M32.414 59.35L50.376 70.5H72.5v-71H33.728L26.5 13.381l19.057 27.08L32.414 59.35z","fill":_vm.properties[1].color,"transform":`translate(${_vm.properties[1].translateX} ${
					_vm.properties[1].translateY
				}) rotate(${_vm.properties[1].rotate} ${_vm.viewboxSize / 2} ${
					_vm.viewboxSize / 2
				}) scale(${_vm.properties[2].scale})`}}),_c('path',{style:({ mixBlendMode: 'overlay' }),attrs:{"filter":"url(#prefix__filter0_f)","d":"M22.216 24L0 46.75l14.108 38.129L78 86l-3.081-59.276-22.378 4.005\n      12.972 20.186-23.35 27.395L22.215 24z","fill":_vm.properties[2].color,"transform":`translate(${_vm.properties[2].translateX}
      ${_vm.properties[2].translateY}) rotate(${_vm.properties[2].rotate} ${
					_vm.viewboxSize / 2
				} ${_vm.viewboxSize / 2}) scale(${_vm.properties[2].scale})`}})]),_c('defs',[_c('filter',{attrs:{"id":"prefix__filter0_f","filterUnits":"userSpaceOnUse","color-interpolation-filters":"sRGB"}},[_c('feFlood',{attrs:{"flood-opacity":0,"result":"BackgroundImageFix"}}),_c('feBlend',{attrs:{"in":"SourceGraphic","in2":"BackgroundImageFix","result":"shape"}}),_c('feGaussianBlur',{attrs:{"stdDeviation":7,"result":"effect1_foregroundBlur"}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }