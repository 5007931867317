<template>
	<v-snackbar
		v-model="show"
		:color="color"
		:timeout="10000"
		:top="$vuetify.breakpoint.smAndDown">
		{{ text }}

		<template #action="{ attrs }">
			<v-btn
				v-bind="attrs"
				dark
				text
				icon
				@click="show = false">
				<v-icon>mdi-close</v-icon>
			</v-btn>
		</template>
	</v-snackbar>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	computed: {
		...mapGetters('notification', [
			'text',
			'color',
		]),
		show: {
			get () {
				return this.$store.state.notification.show
			},
			set (value) {
				this.$store.dispatch('notification/updateShow', value)
			},
		},
	},
}
</script>