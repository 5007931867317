<template>
	<v-app-bar
		flat
		app>

		<v-app-bar-nav-icon
			class="hidden-lg-and-up"
			@click.stop="toggle">
		</v-app-bar-nav-icon>

		<v-app-bar-title>
			<portal-target
				name="app-bar-title"
				class="font-weight-bold">
			</portal-target>
		</v-app-bar-title>

		<portal-target
			name="header-content"
			class="d-flex align-center"
			style="width: 100%;">
		</portal-target>

		<v-spacer></v-spacer>

		<v-avatar
			:size="32"
			class="ml-2">
			<Avatar
				:name="user.full_name"
				variant="beam">
			</Avatar>
		</v-avatar>

	</v-app-bar>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Avatar from '../components/BoringAvatars'

export default {
	components: {
		Avatar,
	},
	computed: {
		...mapGetters('auth', [
			'user',
		]),
	},
	methods: {
		...mapActions('sidebar', [
			'toggle',
		]),
	},
}
</script>
