<template>
	<div class="swiper-container">
		<slot name="before-wrapper" />
		<div class="swiper-wrapper">
			<slot />
		</div>
		<div v-if="defaultPagination" class="swiper-pagination" />
		<div v-if="defaultScrollbars" class="swiper-scrollbar" />
		<div v-if="defaultNavigation" class="swiper-button-next" />
		<div v-if="defaultNavigation" class="swiper-button-prev" />
		<slot name="after-wrapper" />
	</div>
</template>

<script>
import {
	A11y,
	Keyboard,
	Navigation,
	Swiper,
} from 'swiper'
import 'swiper/swiper.min.css'

// see http://idangero.us/swiper/api/#custom-build
Swiper.use([Navigation, Keyboard, A11y])

export default {
	name: 'SwiperSlider',
	props: {
		options: { type: Object, required: true },
	},

	computed: {
		defaultPagination() {
			return this.options.pagination && !this.options.pagination.el
		},
		defaultScrollbars() {
			return this.options.scrollbar && !this.options.scrollbar.el
		},
		defaultNavigation() {
			const { navigation: nav } = this.options
			return nav && (!nav.nextEl && !nav.prevEl)
		},
	},

	watch: {
		options() {
			this.recreateSwiper()
		},
	},

	mounted() {
		this.recreateSwiper()
	},

	updated() {
		this.swiper.update()
	},

	beforeDestroy() {
		if (this.swiper) this.swiper.destroy()
	},

	methods: {
		control(swiper) {
			this.controlled = swiper
			this.recreateSwiper()
		},

		recreateSwiper() {
			if (this.swiper) this.swiper.destroy()

			this.swiper = new Swiper(
				this.$el,
				{
					...this.options,
				},
				// merge(
				// 	{
				// 		preloadImages: false,
				// 		keyboard: {
				// 			enabled: true,
				// 			onlyInViewport: true
				// 		},
				// 		a11y: true,
				// 		navigation: {}
				// 	},
				// 	this.options,
				// 	{
				// 		controller: {
				// 			control: this.controlled
				// 		}
				// 	}
				// )
			)

			this.$emit('update:swiper', this.swiper)
		},
	},
}
</script>

<style>
a {
	text-decoration: none;
}
.swiper-slide {
	width: auto;
	max-width: 5em;
}

.swiper-wrapper .swiper-slide {
	color: inherit;
}
</style>