/* eslint-disable camelcase */
import ApiModel from './ApiModel'

/**
 * Result model
 */
class Result extends ApiModel {

	static entity = 'results'

	static apiResource = 'results'

	static fields () {
		return {
			id: this.number(null).nullable(),
			user_id: this.number(null).nullable(),
			test_id: this.number(null).nullable(),
			test: this.attr({}),
			result: this.number(null).nullable(),
			data: this.attr({}),
			created_at: this.string(null).nullable(),
			updated_at: this.string(null).nullable(),
		}
	}

	/**
	 * Get all Results for User
	 * @param {Number} userid User id
	 * @param {Object} params Query params
	 */
	static async fetchGetAllByUser(userid, params) {
		const { data } = await this.api().getAllByUser(userid, params)
		return this.create({ data })
	}
}

export default Result