/* eslint-disable no-console */
import { apiFactory } from '../../api/apiFactory'

const usersApi = apiFactory.get('users')
const usersLessonsApi = apiFactory.get('usersLessons')

const state = {
	user: null,
	lessons: [],
}

const getters = {
	user: ({ user }) => user,
	lessons: ({ lessons }) => lessons,
}

const mutations = {
	SET_USER(state, payload) {
		state.user = payload
	},
	SET_LESSONS(state, payload) {
		state.lessons = payload
	},
}

const actions = {
	async loadUser({ commit }, userid) {
		try {
			const { data } = await usersApi.get(userid)
			commit('SET_USER', data)
		} catch (error) {
			console.error(error)
		}
	},
	async loadLessons({ commit }, userid) {
		try {
			const { data } = await usersLessonsApi.getAll(userid)
			commit('SET_LESSONS', data)
		} catch (error) {
			console.error(error)
		}
	},
	async attachLesson(state, payload) {
		try {
			await usersLessonsApi.create(payload)
			// commit('SET_LESSONS', data)
		} catch (error) {
			console.error(error)
		}
	},
	async detachLesson(state, payload) {
		try {
			await usersLessonsApi.delete(payload)
			// commit('SET_LESSONS', data)
		} catch (error) {
			console.error(error)
		}
	},
}

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
}