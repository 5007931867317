import api from './api'

const resource = '/lectures'

export default {

	/**
	 * Get list of the lectures
	 *
	 * @returns {Array} lectures
	 */
	getAll(params) {
		return api.get(`${resource}`, {
			params,
		})
	},

	/**
	 * Get Lecture model
	 *
	 * @param {Number} id lecture id
	 * @returns {Object} lecture
	 */
	get(id) {
		return api.get(`${resource}/${id}`)
	},
	
}