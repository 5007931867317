<template>
	<v-overlay :value="loading">
		<v-progress-circular
			indeterminate
			size="64"
			color="secondary"/>
	</v-overlay>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	computed: {
		...mapGetters('loader', [
			'loading',
		]),
	},
}
</script>