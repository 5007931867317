/* eslint-disable camelcase */
import ApiModel from './ApiModel'
import User from './User'

/**
 * Group model
 */
class Group extends ApiModel {

	static entity = 'groups'

	static apiResource = 'groups'

	static fields () {
		return {
			id: this.number(null).nullable(),
			name: this.string(null).nullable(),
			course_id: this.number(null).nullable(),
			course: this.attr({}),
			users: this.attr([], value => value.map(user => new User(user))),
			teachers: this.attr([]),
			created_at: this.string(null).nullable(),
			updated_at: this.string(null).nullable(),
		}
	}

	get users_count() {
		return this.users.length
	}
}

export default Group