<template>
	<v-text-field
		v-model="innerValue"
		v-bind="$attrs"
		v-on="$listeners"
		class="expanding-field"
		:class="{ closed }"
		:background-color="backgroundColor"
		@focus="focused = true"
		@blur="focused = false">
	</v-text-field>
</template>

<script>

export default {
	props: {
		value: {
			type: null,
		},
	},
	data: () => ({
		innerValue: '',
		focused: false,
	}),
	computed: {
		closed() {
			return !this.focused && !this.innerValue
		},
		backgroundColor() {
			return this.closed
				? 'transparent'
				: null
		},
	},
	watch: {
		innerValue(newVal) {
			this.$emit('input', newVal)
		},
		value(newVal) {
			this.innerValue = newVal
		},
	},
	created() {
		if (this.value) {
			this.innerValue = this.value
		}
	},
}
</script>

<style lang="scss" scoped>
::v-deep.expanding-field {
	transition: max-width 0.3s;

	.v-input__slot {
		cursor: pointer !important;
	}

	&.closed {
		max-width: 45px;
	}
}
</style>
