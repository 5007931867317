import axios from 'axios'
import { stringify } from 'qs'
import { errorHandler } from './apiInterceptors'

const api = axios.create({
	baseURL: process.env.VUE_APP_API,
})

/*
 * The interceptor here ensures that we check for the token in local storage every time an ajax request is made
 */
api.interceptors.request.use(
	(config) => {
		const token = localStorage.getItem('token')
		if (token) {
			config.headers['Authorization'] = `Bearer ${token}`
		}

		config.paramsSerializer = params => stringify(params)
		
		return config
	},
	(error) => {
		return Promise.reject(error)
	},
)

api.interceptors.response.use(
	response => response,
	// eslint-disable-next-line complexity
	error => {
		console.warn('error.config.url', error.config.url)
		const { config } = error
		if (!error.status && config.method === 'post' && config._resend === true) {
			// const { url, method, data } = config
			// return savedRequests.store(url, method, data)
		// eslint-disable-next-line prefer-template
		} else if (error.response.status === 401 && error.config.url !== '/refresh' && error.config.url !== 'login') {
			return errorHandler(error)
		}
		return Promise.reject(error)
	},
)

export default api