/* eslint-disable camelcase */
import ApiModel from './ApiModel'

/**
 * Lesson model
 */
class Lesson extends ApiModel {

	static entity = 'lessons'

	static apiResource = 'lessons'

	static fields () {
		return {
			id: this.number(null).nullable(),
			order: this.number(null).nullable(),
			name: this.string(null).nullable(),
			text: this.string(null).nullable(),
			datetime: this.string(null).nullable(),
			duration: this.string(null).nullable(),
			course_id: this.number(null).nullable(),
			course: this.attr({}),
			video_id: this.string(null).nullable(),
			files: this.attr([]),
			users: this.attr([]),
			tests: this.attr([]),
			is_active: this.boolean(false),
			created_at: this.string(null).nullable(),
			updated_at: this.string(null).nullable(),
		}
	}

	get tests_count() {
		return this.tests.length
	}

	get files_count() {
		return this.files.length
	}

	get users_count() {
		return this.users.length
	}

	/**
	 * Get all Lessons by Course ID
	 * and insert response to Store
	 * @param {*} courseid Course ID
	 */
	static async fetchGetAllByCourse(courseid) {
		const { data } = await this.api().getByCourse(courseid)
		return this.create({ data })
	}

	/**
	 * Attach files to lesson
	 * @param {Number} id Lesson ID
	 * @param {File} file File to import
	 */
	static createFile(id, file) {
		return this.api().createFile(id, file)
	}

	/**
	 * Remove files from lesson
	 * @param {Number} id Lesson ID
	 * @param {Number} fileId File Media ID
	 * @param {File} file File to import
	 */
	static deleteFile(id, fileId) {
		return this.api().deleteFile(id, fileId)
	}
}

export default Lesson