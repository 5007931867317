<template>
	<v-snackbar v-model="shack"
		:timeout="timeout"
		:vertical="$vuetify.breakpoint.xsOnly"
		:top="$vuetify.breakpoint.smAndDown">

		{{ shackText }}

		<template #action>
			<v-btn
				text
				color="success"
				@click.native="refreshApp"
				class="text-none"
				small>
				<v-icon left small>mdi-reload</v-icon>
				{{ snackBtnText }}
			</v-btn>
		</template>
	</v-snackbar>
</template>

<script>
export default {
	data() {
		return {
			refreshing: false,
			registration: null,
			shack: false,
			shackText: 'Доступна нова версія 🎉',
			snackBtnText: 'Перезавантажити',
			timeout: -1,
		}
	},
	created() {
		// Listen for swUpdated event and display refresh snackbar as required.
		document.addEventListener('swUpdated', this.showRefreshUI, {
			once: true,
		})
		// Refresh all open app tabs when a new service worker is installed.
		navigator?.serviceWorker?.addEventListener('controllerchange', () => {
			if (this.refreshing) return
			this.refreshing = true
			window.location.reload()
		})
	},
	methods: {
		showRefreshUI(e) {
			// Display a snackbar inviting the user to refresh/reload the app due
			// to an app update being available.
			// The new service worker is installed, but not yet active.
			// Store the ServiceWorkerRegistration instance for later use.
			this.registration = e.detail
			this.shack = true
		},
		refreshApp() {
			this.shack = false
			// Protect against missing registration.waiting.
			if (!this.registration || !this.registration.waiting) {
				return
			}
			this.registration.waiting.postMessage('skipWaiting')
		},
	},
}
</script>