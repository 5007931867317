import User from './User'

/**
 * Teacher model
 */
class Teacher extends User {

	static entity = 'teachers'

	/**
	 * Get all models from API and insert response to Store
	 */
	static async fetchGetAll(params) {
		params = {
			...params,
			filter: {
				'roles.slug': 'teacher',
			},
		}
		const { data } = await this.api().getAll(params)
		return this.create({ data })
	}
}

export default Teacher
