import api from './api'

const resource = 'lessons'

export default {
	
	/**
	 * Get all Lessons
	 */
	getAll() {
		return api.get(`${resource}`)
	},

	/**
	 * Get lessons by Course id
	 * @param {Number} courseid Course id
	 */
	getByCourse(courseid) {
		return api.get(`courses/${courseid}/${resource}`)
	},

	/**
	 * Get Lessson by id
	 * @param {Number} lessonid
	 */
	get(lessonid) {
		return api.get(`${resource}/${lessonid}`)
	},

	/**
	 * Create Lesson Model
	 * @param {Object} payload Lesson Model
	 */
	create(payload) {
		return api.post(`${resource}`, payload)
	},

	/**
	 * Update Lesson Model
	 * @param {Object} payload Lesson Model
	 */
	update(payload) {
		return api.put(`${resource}/${payload.id}`, payload)
	},

	/**
	 * Delete Lesson Model
	 * @param {Object} payload Lesson Model
	 */
	delete(payload) {
		return api.put(`${resource}/${payload.id}`)
	},

	/**
	 * Create File attached to Lesson
	 * @param {Number} id Lesson Model ID
	 * @param {File} file File to import
	 */
	createFile(id, file) {
		const formData = new FormData()
		formData.append('files', file)

		return api.post(`${resource}/${id}/files`, formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		})
	},

	/**
	 * Delete File attached to Lesson
	 * @param {Number} id Lesson Model ID
	 * @param {Number} fileId Media Model ID
	 */
	deleteFile(id, fileId) {
		return api.delete(`${resource}/${id}/files/${fileId}`)
	},
	
}